import { PageData } from '@innedit/innedit';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import CMSView from '../../../../../../components/View';
import Form from '../../../../../../containers/Espace/Form';
import params from '../../../../../../params/page/index.json';
import TemplateEspace from '../../../../../../templates/espace';
import requireEspace, {
  EspaceProps,
} from '../../../../../../utils/requireEspace';
import { UserProps } from '../../../../../../utils/requireUser';

const PagePagesCreate: FC<PageProps & EspaceProps & UserProps> = props => {
  const {
    espace,
    user,
    params: { channelId, espaceId },
  } = props;

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <Form
          {...props}
          itemPathnamePrefix={`/espaces/${espaceId}/canaux/${channelId}/pages/`}
          model={new PageData({ channelId, espaceId, params })}
          name="page"
          type="create"
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PagePagesCreate);
